ul.list{
  list-style-type: disc;
  li::marker {
    color: red;
  }
  
}
.onlytechGray {
  border: 1px #b4b4b4 solid;
}
.App {
  overflow: hidden;
  font-family: "Inter", sans-serif;
  a {
    text-decoration: none;
  }

  .btn {
    font-weight: 800;
    border: none;
  }

  .row {
    //min-height: 100vh;
    padding-top: 20px;
    //padding-left: 20px;
    
    .col {
      display: flex;
      flex-direction: column;
       
     // margin-bottom: 20px;
    }
  }
  .subtitle {
    font-family: "Inter";
    font-style: normal;
    font-weight: 900;
    line-height: 102.5%;
  }
  .subtitle2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 900;
    line-height: 130%;
  }
  .privacy-link {
    cursor: pointer;
  }

  .main {
    
    border-radius: 21px;
    padding: 30px;
    @media only screen and (max-width: 600px) {
      padding: 30px;
    }

    .services-link {
      grid-area: services;
    }
    .news-link {
      grid-area: news;
    }
    .onlymusix-link {
      grid-area: onlymusix;
    }
    .poap-link {
      grid-area: poap;
    }
    .grid-container {
      display: grid;
      grid-template-areas:
        "services services news"
        "services services news"
        "poap poap news"
        "onlymusix onlymusix news";
      gap: 20px;
      div {
        border-radius: 21px;
        padding: 20px;
        @media only screen and (max-width: 600px) {
          margin-top: 20px;
        }
      }
      @media only screen and (max-width: 600px) {
        grid-template-areas:
          "services"
          "news"
          "onlymusix"
          "poap";
      }
    }
    .grid-container-pop {
      display: grid;
      grid-template-areas:
        "services services news"
        "services services news"
        "services services news"
        "onlymusix onlymusix news";
      gap: 20px;
      div {
        border-radius: 21px;
        padding: 20px;
        @media only screen and (max-width: 600px) {
          margin-top: 20px;
        }
      }
      @media only screen and (max-width: 600px) {
        grid-template-areas:
          "services"
          "news"
          "onlymusix"
          "poap";
      }
    }
    .grid-container-blockchain {
      display: grid;
      grid-template-areas:
        "services services news"
        "services services news"
        "poap poap news"
        "onlymusix onlymusix news";
      gap: 20px;
      div {
        border-radius: 21px;
        padding: 20px;
        @media only screen and (max-width: 600px) {
          margin-top: 20px;
        }
      }
      @media only screen and (max-width: 600px) {
        grid-template-areas:
          "services"
          "news"
          "onlymusix"
          "poap";
      }
    }
  }

  .nav {
    border-radius: 21px;
    img {
      //height: 20%;
    }
    .navbar {
      margin-top: 15px;
      margin-bottom: 15px;
      @media only screen and (max-width: 600px) {
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }
  }

  .primary {
    border-radius: 21px;
    margin-top: 20px;
    padding: 38px;
    
    @media only screen and (max-width: 600px) {
      padding: 15px;
    }
  } 
  

  .secondary {
    //height: 0%;
    border-radius: 21px;
    margin-top: 20px;
    //margin-bottom: 20px;  
    padding: 38px;

  }

  .newsbox {
    //height: 0%;
    border-radius: 21px;
    margin-top: 20px;
    //margin-bottom: 20px;  
    padding: 20px;

  }

  
  .footer {
  
    padding: 20px;
    margin-top: 20px; 

    margin-bottom: 20px;
    border-radius: 21px;

    .row {
      //min-height: 0px;
      margin-top: 20px;
      margin-bottom: 20px;     
    }
    @media only screen and (max-width: 600px) {
      padding: 0px;
      margin-top: 20px; 
      margin-left: 0px;
      margin-right: 0px; 
      .row {
        margin-top: 0px;
        
      }
      .col {
        padding: 0px;
        margin: 4px;
      }
      img {
        width: 100%;
      }
    }
  }

  .Home {
    //min-height: 100%;
    .main {
      //height: 100%;
    }
    .primary {
      border: none;
      img {
        max-width: 90%;
        @media only screen and (max-width: 600px) {
          max-width: 100%;
        }
      }
    }
  }

  .Onlymusix {
    background-image: url("../media/omx-background.webp");
    background-repeat: repeat-x;
    background-attachment: fixed;

    min-//height: 100%;
    .primary {
      border: none;
      img {
        max-width: 50%;
        @media only screen and (max-width: 600px) {
          max-width: 80%;
        }
      }
    }
  }
  .Poapup {
    background-image: url("");
    background-repeat: repeat-x;
    background-attachment: fixed;

    //min-height: 100%;
    .primary {
      border: none;
      img {
        max-width: 90%;
        @media only screen and (max-width: 600px) {
          max-width: 100%;
        }
      }
    }
  }
  .News {
    //min-height: 100%;

    .main {
      ////height: 40%;
    }
    .secondary {
      .main-subtitle {
        font-size: 20px;
        margin-bottom: 5px;
      }
      .main-text {
      }
      .news-disclaimer {
        @media only screen and (max-width: 600px) {
          margin-bottom: 40px;
          margin-top: 40px;
        }
      }
      .row {
        //min-height: 0px;
        padding: 0;
      }
      img {
        //height: 120px;
        width: 120px;
        margin-bottom: 20px;
        border-radius: 11px;
        opacity: 0.91;
      }
    }
  }

  .Services {
    //min-height: 100%;
    .main {
     // //height: 40%;
    }
    .secondary {
      border: none;
      padding: 0px;
      border-radius: 21px;
      margin-top: 20px;
      .nav-link {
        //height: 100%;
        width: 100%;
        padding: 20px;
        @media only screen and (max-width: 600px) { 
        padding: 15px;
        }
      }
    }
    .secondary2 {
      border: 1px;
      padding: 0px;
      border-radius: 21px;
      margin-top: 20px;
      .nav-link {
        //height: 100%;
        width: 100%;
        padding: 20px;
      }
    }
    .secondary3 {
      border: 1px;
      padding: 0px;
      border-radius: 21px;

      .nav-link {
        //height: 100%;
        width: 100%;
        padding: 20px;
      }
    }
    .primary {
      img {
        max-width: 80%;
        @media only screen and (max-width: 600px) {
          max-width: 90%;
        }
      }
    }
    .blockchain {
      grid-area: blockchain;
    }
    .contract {
      grid-area: contract;
    }
    .token {
      grid-area: token;
    }
    .decentralized {
      grid-area: decentralized;
    }
    .consulting {
      grid-area: consulting;
    }
    .grid-container {
      //min-height: 100%;
      display: grid;
      grid-template-areas:
        "token blockchain"
        "token contract"
        "decentralized contract"
        "decentralized consulting";
      @media only screen and (max-width: 600px) {
        grid-template-areas:
          "blockchain"
          "contract"
          "consulting"
          "token"
          "decentralized";
      }
      gap: 20px;
      div {
        border-radius: 21px;
        cursor: pointer;
      }
      div:hover {
        transition: outline 0.1s ease-in;
        -webkit-transition: outline 0.1s ease-in;
      }
      .btn {
        font-weight: 800;
      }
    }

    .grid-container-blockchain {
      //min-height: 100%;
      display: grid;
      grid-template-areas:
        "blockchain token"
        "contract consulting"
        "contract consulting"
        "decentralized decentralized";
      @media only screen and (max-width: 600px) {
        grid-template-areas:
          "blockchain"
          "contract"
          "consulting"
          "token"
          "decentralized";
      }
      gap: 20px;
      div {
        border-radius: 21px;
        cursor: pointer;
      }
      div:hover {
        transition: outline 0.1s ease-in;
        -webkit-transition: outline 0.1s ease-in;
      }
      .btn {
        font-weight: 800;
      }
    } 
  }

  .main-title {
    font-style: normal;
    font-weight: 900;
    font-size: 96px;
    line-height: 100%;
    letter-spacing: 0.025em;
    @media only screen and (max-width: 600px) {
      font-size: 46px;
    }
  }
  .main-title-services {
    font-style: normal;
    font-weight: 900;
   // font-size: 55px;
    line-height: 92.5%;

    letter-spacing: 0.025em;
    @media only screen and (max-width: 600px) {
      font-size: 20px;
    }
  }

  .main-subtitle {
    font-family: "Inter";
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 102.5%;
    @media only screen and (max-width: 600px) {
      font-size: 20px;
    }
  }
  .main-text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
   // font-size: 16px;
    line-height: 122.5%;
    width: 95%;
    //text-shadow: 1px 1px 2px rgb(175, 175, 175);
  }
  .sub-text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 122.5%;
    width: 95%;
  } 
}
.boldText{
  font-weight: bold;
}
.light {
  
  $primary: #1045ff;
  $border: #b4b4b4;
  $background: #ffffff;
  $text: #1f1f1f;
  $omx: #fc0048;
  $poap: #eb9736;
  $poapbg: #32332f;
  .onlytechBlue {
    --tw-bg-opacity: 1;
    background-color: $primary; 
  } 
  .textLink{
    color: $text;
  }
  .textLink:hover{
    color: $primary; 
  }
  background-color: $background;
  color: $text;
  .InlineBox{
    display: flex;
    flex-direction: row; 
    margin-left: auto;
    margin-right: auto;
  }
  .consultancyForm{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
  }
 .checkBox{
  margin-right:5px;
 } 
  .EmailBox{
    border-radius: 12px;

    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }
  .EmailBoxDisabled{
    border-radius: 12px;

    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  }
  .EmailSubscribeBtn{
    border-radius: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 5px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: $primary;
    color:white;
    font-weight: bold;
  }
  .EmailSubscribeBtnDeactivated{
    border-radius: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 5px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: white;
    color:white;
    font-weight: bold;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  } 
  .btn {
    background-color: $background;
    color: $text;
  }
  .btn:hover {
    background-color: $background;
    color: $primary;
  }
  .btn:active {
    background-color: $background !important;
    color: $primary !important;
  }
  .btn-news {
    background-color: $primary;
    color: $background;
  }
  .btn-news:hover {
    background-color: $primary;
    color: $background;
  }
  .btn-news:active {
    background-color: $primary !important;
    color: $background !important;
  }

  .nav-link {
    color: $text;
    svg {
      color: $text;
    }
  }
  .nav-link:hover {
    color: $text !important;
  }
  .nav-link:focus {
    color: $text !important;
  }
  .nav-link:active {
    color: $text !important;
  }
  .nav-link:visited {
    color: $text !important;
  }

  .dropdown-menu {
    z-index: 100;
    background-color: $background;
    .dropdown-item {
      color: $text;
    }
    .dropdown-item:hover {
      background-color: $primary;
      color: $background;
    }
  }

  .main,
  .footer,
  .primary,
  .secondary,
  .nav {
    border: 1px $border solid;
  }
  .nav2 {
    border: 0px $border solid;
  } 
  .squareBorder{
    border: 1px $border solid;
    border-radius: 21px;
    padding: 20px;
    }
  .footer {
    a {
      color: $text;
    }
  }

  .Home,
  .News,
  .Services {
    .main {
      background-color: $primary;
      color: $background;
    }
  }

  .grid-container {
    div {
      border: 3px $background solid;
    }
  }
  .grid-container-pop {
    div {
      border: 3px $background solid;
    }
  }
  .grid-container-blockchain {
    div {
      border: 3px $background solid;
    }
  }
  .Services {
    .grid-container {
      div {
        border: 1px $border solid;
        background-color: $background;
        color: $text;
      }
      div:hover {
        .nav-link {
          color: $primary !important;
          
        }
        outline: 5px $primary solid;
        @media only screen and (max-width: 600px) {
          outline: none;
        }
      }
    }
    .grid-container-pop {
      div {
        border: 1px $border solid;
        background-color: $background;
        color: $text;
      }
      div:hover {
        .nav-link {
          color: $primary !important;
          
        }
        outline: 5px $primary solid;
        @media only screen and (max-width: 600px) {
          outline: none;
        }
      }
    } 
    .grid-container-blockchain {
      div {
        border: 1px $border solid;
        background-color: $background;
        color: $text;
      }
      div:hover {
        .nav-link {
          color: $primary !important;
        }
        outline: 5px $primary solid;
        @media only screen and (max-width: 600px) {
          outline: none;
        }
      }
    }
  }
  .Onlymusix {
    background-color: $text;
    color: $background;
    .footer {
      a {
        color: $background;
      }
    }
    .btn:hover {
      background-color: $background;
      color: $omx;
    }
    .btn:active {
      background-color: $background !important;
      color: $omx !important;
    }
    .main {
      border: none;
      background-color: $omx;
      color: $background;
      .grid-container {
        .main-text {
          font-weight: 600;
          
        }
      }
      .grid-container-pop {
        .main-text {
          font-weight: 600;
          
        }
      }
      .grid-container-blockchain {
        .main-text {
          font-weight: 600;
          
        }
      }
    }

    .nav-link {
      color: $background;
      svg {
        color: $background;
      }
    }
    .nav-link:hover {
      color: $background !important;
    }
    .nav-link:focus {
      color: $background !important;
    }
    .nav-link:active {
      color: $background !important;
    }
    .nav-link:visited {
      color: $background !important;
    }
  }
  .Poapup {
    background-color: $poapbg;
    color: $background;
    .footer {
      a {
        color: $background;
      }
    }
    .btn:hover {
      background-color: $background;
      color: $poap;
    }
    .btn:active {
      background-color: $background !important;
      color: $poap !important;
    }
    .main {
      border: none;
      background-color: $poap;
      color: $background;
      .grid-container {
        .main-text {
          font-weight: 600;
        }
      }
      .grid-container-pop {
        .main-text {
          font-weight: 600;
        }
      }
      .grid-container-blockchain {
        .main-text {
          font-weight: 600;
        }
      }
    }

    .nav-link {
      color: $background;
      svg {
        color: $background;
      }
    }
    .nav-link:hover {
      color: $background !important;
    }
    .nav-link:focus {
      color: $background !important;
    }
    .nav-link:active {
      color: $background !important;
    }
    .nav-link:visited {
      color: $background !important;
    }
  }
}

.dark {
  $primary: #adbffe;
  $border: #b4b4b4;
  $background: #1f1f1f;
  $text: #ffffff;
  $omx: #fc0048;
  $pop: #eb9736;
  $poapbg: #32332f;
  .onlytechBlue {
    --tw-bg-opacity: 1;
    background-color: $primary; 
  } 
  background-color: $background;
  color: $text;
  .InlineBox{
    display: flex;
    flex-direction: row; 
    margin-left: auto;
    margin-left: auto;
  }
  .textLink{
    color: $text;
  }
  .textLink:hover{
    color: $primary; 
  }
  .EmailBox{
    border-radius: 12px;

    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

  }
  .consultancyForm{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
  }
 .checkBox{
  margin-right:5px;
 } 
  .EmailBoxDisabled{
    border-radius: 12px;

    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  }
  .EmailSubscribeBtn{
    border-radius: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 5px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: $primary;
    color:white;
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  }
  .EmailSubscribeBtnDeactivated{
    border-radius: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 5px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: white;
    color:white;
    font-weight: bold;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;  } 
  .btn {
    background-color: $background;
    color: $text;
  }
  .btn:hover {
    background-color: $background;
    color: $primary;
  }
  .btn:active {
    background-color: $background !important;
    color: $primary !important;
  }
  .btn-news {
    background-color: $primary;
    color: $background;
  }
  .btn-news:hover {
    background-color: $primary;
    color: $background;
  }
  .btn-news:active {
    background-color: $primary !important;
    color: $background !important;
  }

  .nav-link {
    color: $text;
    svg {
      color: $text;
    }
  }
  .nav-link:hover {
    color: $text !important;
  }
  .nav-link:focus {
    color: $text !important;
  }
  .nav-link:active {
    color: $text !important;
  }
  .nav-link:visited {
    color: $text !important;
  }

  .dropdown-menu {
    background-color: $background;
    .dropdown-item {
      color: $text;
    }
    .dropdown-item:hover {
      background-color: $primary;
      color: $background;
    }
  }

  .main,
  .footer,
  .primary,
  .secondary,
  .nav {
    border: 1px $border solid;
  }
  
  .squareBorder{
    border: 1px $border solid;
    border-radius: 21px;
    padding: 20px;
    }
  .footer {
    a {
      color: $text;
    }
  }
  
  .Home,
  .News,
  .Services {
    .main {
      background-color: $primary;
      color: $background;
    }
  }

  .grid-container {
    div {
      border: 3px $background solid;
    }
  }
  .grid-container-pop {
    div {
      border: 3px $background solid;
    }
  }
  .grid-container-blockchain {
    div {
      border: 3px $background solid;
    }
  }
  .Services {
    .grid-container {
      div {
        border: 1px $border solid;
        background-color: $background;
        color: $text;
      }
      div:hover {
        .nav-link {
          color: $primary !important;
        }
        outline: 5px $primary solid;
        @media only screen and (max-width: 600px) {
          outline: none;
        }
      }
    }
    .grid-container-pop {
      div {
        border: 1px $border solid;
        background-color: $background;
        color: $text;
      }
      div:hover {
        .nav-link {
          color: $primary !important;
        }
        outline: 5px $primary solid;
        @media only screen and (max-width: 600px) {
          outline: none;
        }
      }
    }
    .grid-container-blockchain {
      div {
        border: 1px $border solid;
        background-color: $background;
        color: $text;
      }
      div:hover {
        .nav-link {
          color: $primary !important;
        }
        outline: 5px $primary solid;
        @media only screen and (max-width: 600px) {
          outline: none;
        }
      }
    }
  }
  .Onlymusix {
    background-color: $background;
    color: $text;
    .btn:hover {
      background-color: $background;
      color: $omx;
    }
    .btn:active {
      background-color: $background !important;
      color: $omx !important;
    }
    .main {
      border: none;
      background-color: $omx;
      color: $background;
      .grid-container {
        .main-text {
          font-weight: 600;
        }
      }
      .grid-container-pop {
        .main-text {
          font-weight: 600;
        }
      }
      .grid-container-blockchain {
        .main-text {
          font-weight: 600;
        }
      }
    }
  }

  .Poapup {
    background-color: $background;
    color: $text;
    .btn:hover {
      background-color: $background;
      color: $pop;
    }
    .btn:active {
      background-color: $background !important;
      color: $pop !important;
    }
    .main {
      border: none;
      background-color: $pop;
      color: $background;
      .grid-container {
        .main-text {
          font-weight: 600;
        }
      }
      .grid-container-pop {
        .main-text {
          font-weight: 600;
        }
      }
      .grid-container-blockchain {
        .main-text {
          font-weight: 600;
        }
      }
    }
  }
}